import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './App.css'; // Assuming you have a CSS file for styling
import { FaClipboard, FaEdit } from 'react-icons/fa';
import { MdContentCopy } from "react-icons/md";
import ChatParagraph from './ChatParagraph';
import { jsPDF } from "jspdf";
import { MdOutlineSaveAlt } from "react-icons/md";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { SlOptions } from "react-icons/sl";
import { GrDocumentPdf } from "react-icons/gr";
import { TbFileTypeDocx } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa";
import { useNavigate } from 'react-router';

const CopyTextComponent = (props) => {
  const [text, setText] = useState('This is the text you can copy!');
  const [copied, setCopied] = useState(false);
  const [showOptions,setShowOptions] = useState(false);

  const navigate = useNavigate();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Reset the copied status after 2 seconds
  };

  const handleDownload = (text) => {
    const doc = new jsPDF();
      // Set font size
  doc.setFontSize(14); // Set the font size to 12

  // Add title
  doc.setFont("helvetica", "bold");
  // doc.text("My PDF Document", 105, 20, { align: "center" });

  // Set font size for the paragraph
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12); // Set font size for paragraph
  
    const margin = 10; // Left and right margins
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const usableWidth = pageWidth - 2 * margin;
    const lineHeight = 10; // Vertical space between lines
  
    // Split text into lines
    const lines = doc.splitTextToSize(text, usableWidth);
  
    let cursorY = margin; // Initial vertical position for text
  
    lines.forEach((line) => {
      if (cursorY + lineHeight > pageHeight - margin) {
        // Add a new page if text goes beyond the current page
        doc.addPage();
        cursorY = margin; // Reset vertical position
      }
      doc.text(line, margin, cursorY);
      cursorY += lineHeight; // Move to the next line
    });

     // Create an instance of jsPDF
  
  // Add Date and Time to the PDF (current time)
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(); // or custom format
  
  // Set up the header
  // doc.setFontSize(18);
  // doc.setFont("helvetica", "bold");
  // doc.text("My PDF with Date and Time", 20, 20);
  


  // Add a normal text paragraph
  doc.setFontSize(12);
  doc.setFont("times", "normal");
  // doc.text("This is a regular text paragraph with dynamic content.", 20, 50);
  
  // Add a Header (larger, bold font)
  // doc.setFontSize(16);
  // doc.setFont("times", "bold");
  // doc.text("This is a Header", 20, 70);
  
  // Add some dynamic content styled as a "code block"
  //const codeText = `const greeting = "Hello, world!";\nconsole.log(greeting);`;
  
  // Code block style: use a monospaced font for code
  // doc.setFont("courier", "normal");
  // doc.setFontSize(10);
  // doc.text(codeText, 20, 90);

  // Add another section with different content styles
  // doc.setFontSize(12);
  // doc.setFont("times", "normal");
  // doc.text("Another normal text paragraph below the code block.", 20, 130);

    // Add current Date and Time at the top
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
   // doc.text(`Generated on: ${formattedDate}`, 20, 30);

  // Save the PDF
  doc.save("generated_document.pdf");

  };

  const handleDownloadDocx = async (text) => {
    // Create a new document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun(text)],
            }),
          ],
        },
      ],
    });

    // Generate the document
    const blob = await Packer.toBlob(doc);

    // Save the document
    saveAs(blob, "document.docx");
  };

  const generateDocx = (text) => {
    // Page Setup
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 720, // 1 inch
                bottom: 720, // 1 inch
                left: 720, // 1 inch
                right: 720, // 1 inch
              },
            },
          },
          children: text.split("\n").map((line) => 
            new Paragraph({
              children: [new TextRun({ text: line.trim(), font: "Arial", size: 24 })], // Font size 12pt
              alignment: "left", // Align text to the left
            })
          ),
        },
      ],
    });
  
    // Generate and Save the Document
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "document.docx");
    });
  };

  function updateOptions(){
    setShowOptions(!showOptions)
  }

  function handleEdit(text){
    //
   
    navigate('/edit',{ state: { role:'assistant',content:text } })
  }

  return (
    <div className="copy-container">
      
      {/* <LinkifyText text={props.text} /> */}
      <ChatParagraph text={props.text} />
     
      {props.role !== 'user' ? <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      {showOptions && <CopyToClipboard text={props.text} onCopy={handleCopy}>
       <p> <MdContentCopy /> 
       {copied && "Copied!"}</p>
      </CopyToClipboard>}

      {showOptions && <FaEdit style={{marginTop:20,cursor:'pointer' }} color='black' onClick={() => handleEdit(props.text)}/>}

       {showOptions && <FaFilePdf style={{marginTop:20,cursor:'pointer' }} color='black' onClick={() => handleDownload(props.text)}/>}
       {showOptions && <TbFileTypeDocx style={{marginTop:20,cursor:'pointer' }} onClick={() => generateDocx(props.text)} color='black' />}
       {!showOptions && <SlOptions onClick={updateOptions} style={{marginTop:20,cursor:'pointer' }} />}
        </div>
        :
        <MdContentCopy />
        }
    </div>
  );
};

export default CopyTextComponent;
