import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router';
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './Navbar.css'; // Add custom styles here
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, onValue, ref } from 'firebase/database';

const NavbarComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRightMenuOpen,setIsRightMenuOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [historyData,setHistoryData] = useState([]);

  const [user,setUser] = useState(null);

  const auth = getAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleRightMenu = () => {
    setIsRightMenuOpen(!isRightMenuOpen);
  };

  async function signUserOut(){
    try {
      await signOut(auth);
      navigate('/');

    } catch (error) {
      console.log(error)
    }
  }

  const fetchUserData = useCallback(() => {
     // Subscribe to auth state changes
     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update user state
      if (currentUser) {
        onValue(ref(getDatabase(),`users/${currentUser.uid}`),(userSnapshot) => {
          if (userSnapshot.exists()) {
            setUser(userSnapshot.val())
          }
        })
      }
      setLoading(false); // Remove loading state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  },[user])

  useEffect(() => {
   fetchUserData()
  }, []);

  const fetchHistory = useCallback(() => {
    onValue(ref(getDatabase(),`history/${auth?.currentUser?.uid}`),(historySnapshot) => {
      let myArr = [];
      historySnapshot.forEach((history) => {
        myArr.push(history)
       // console.log(history.val())
      })
      setHistoryData(myArr.reverse())
    })
  },[historyData])

  useEffect(() => {
    if (auth.currentUser) {
      fetchHistory()
    }
  },[])

 async function navigateAndToggle(history){
  try {
    navigate(`/history`,{state:{key:history?.key,...history.val()}})
  } catch (error) {
    console.log(error)
  }
  }

  return (
    <div className="navbar">
      <IconButton onClick={toggleMenu}>
        <MenuIcon />
      </IconButton>
      <IconButton onClick={() => auth?.currentUser ? navigate(`/manage`) : navigate('/subscribe')}>
      <h3 className="navbar-title">{user?.subscribed ? 'ChatGPT Plus' : 'Get Plus' } <img src={require('./src/images/pro.png')} style={{width:25,height:25}} /></h3>
      </IconButton>
      <IconButton onClick={toggleRightMenu}>
        <AccountCircleIcon />
      </IconButton>

      {/* Drawer for Side Menu */}
      <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu}>
        <div style={{ width: 250 }}>
          <List>
            <ListItem button onClick={toggleMenu} component={Link} to="/">
              <ListItemText primary="ChatGPT" />
            </ListItem>
           {auth?.currentUser  ? <>
            {/* <ListItem button onClick={toggleMenu} component={Link} to={`/imagegenerator/${auth?.currentUser?.uid}`}>
              <ListItemText primary="ImageGenerator" />
            </ListItem> */}
            <ListItem 
            button 
            onClick={toggleMenu} 
            component={Link} 
            to={`/manage`}
            >
              <ListItemText primary="Subscription" />
            </ListItem>

            <ListItem 
            button 
            onClick={toggleMenu} 
            component={Link} 
            to={`/ebooks`}
            >
              <ListItemText primary="AI eBooks" />
            </ListItem>

            {
                historyData?.map((history,index) => (
                  <ListItem 
                  key={index}
                  button 
                  onClick={toggleMenu} 
                  component={Link} 
                  to={'/history'}
                  state={{key:history.key,...history.val()}}
                  >
                  <ListItemText 
                  primary={history?.val()?.userMessage?.content} 
                  sx={{
                    whiteSpace: 'nowrap',  // Prevent text from wrapping to the next line
                    overflow: 'hidden',    // Hide any overflowing content
                    textOverflow: 'ellipsis', // Display ellipsis for overflowed text
                    width: '200px',        // Set a fixed width to control overflow
                  }}
                  />
                </ListItem>
                ))
              }

           </> : <>
           {/* <ListItem button onClick={toggleMenu} component={Link} to="/imagegenerator">
              <ListItemText primary="ImageGenerator" />
            </ListItem>  */}
            <ListItem button onClick={toggleMenu} component={Link} to="/subscribe">
              <ListItemText primary="Subscribe" />
              
            </ListItem> 


            <ListItem 
            button 
            onClick={toggleMenu} 
            component={Link} 
            to={`/ebooks`}
            >
              <ListItemText primary="AI eBooks" />
            </ListItem>
           </>
            }
            
          </List>
        </div>
      </Drawer>

      <Drawer anchor="right" open={isRightMenuOpen} onClose={toggleRightMenu}>
        <div style={{ width: 250 }}>
          <List>
           {auth?.currentUser  ? <>
            <ListItem button={'true'} onClick={toggleRightMenu} component={Link} to={`/profile`}>
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button={'true'} onClick={signUserOut} component={Link}>
              <ListItemText primary="Logout" />
            </ListItem>
           </> : <ListItem button={'true'} onClick={toggleRightMenu} component={Link} to="/login">
              <ListItemText primary="Login" />
            </ListItem> }
            
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default NavbarComponent;