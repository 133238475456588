import React, { useState } from "react";
import axios from "axios";
import SearchBar from "./SearchBar";
import BookList from "./BookList";
import "./GoogleBookAPI.css";

const GoogleBookAPI = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBooks = async (query) => {
    if (!query) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/books/v1/volumes?q=${query}&key=${process.env.REACT_APP_GOOGLE_BOOKS_API_KEY}`
      );
      setBooks(response.data.items || []);
    } catch (error) {
      console.error("Error fetching books:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app">
      <SearchBar onSearch={fetchBooks} />
      {loading ? <p>Loading...</p> : <BookList books={books} />}
    </div>
  );
};

export default GoogleBookAPI;
