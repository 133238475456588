// LoginSignup.js
import React, { useState } from "react";
import "./LoginSignup.css";
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, push, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router';

const LoginSignup = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPasswordForgot,setIsPasswordForgot] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      set(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
        uid:auth?.currentUser?.uid,
        email,
        date:new Date().toString(),
        subscribed:false,
        subscriptionId:""
      })
     // alert('Signup successful!');
     navigate(`/`)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
     // alert('Login successful!');
     navigate(`/`)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  return (
   <div id="body">
     <div className="auth-container">
     <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:10}}>
          <img src={require('./src/images/splash-icon.png')} style={{width:100,height:100}} alt="" />
          <h1 style={{color:'whitesmoke'}}>ChatGPT AI X</h1>
          </div>
      <div style={{color:'white'}} className="auth-card">
     
        {/* <h2>{isLogin ? "Login" : "Sign Up"}</h2> */}
        <form onSubmit={isLogin ? handleLogin : handleSignup}>
        {error && <Alert variant="danger">{error}</Alert>}
          <div className="input-group">
            <label>Email</label>
            <input 
            style={{fontSize:16}}
            type="email" 
            placeholder="Enter your email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input 
            style={{fontSize:16}}
            type="password" 
            placeholder="Enter your password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            />
          </div>
         {isLogin ? <button type="submit" className="auth-button">
            {loading ? "Logging.." : "Log In"}
          </button> 
          :
          <button type="submit" className="auth-button">
          {loading ? "Signing.." : "Sign Up"}
        </button>
          }
        </form>
        <p className="toggle-text">
          {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
          <span onClick={() => navigate('/subscribe')} className="toggle-link">
            {isLogin ? "Sign Up" : "Login"}
          </span>
        </p>
       {isLogin && <p className="toggle-text">
          {"Did you forget password?"}{" "}
          <span onClick={() => navigate('/forgotpassword')} className="toggle-link">
            Reset password
          </span>
        </p>}
      </div>
    </div>
   </div>
  );
};

export default LoginSignup;