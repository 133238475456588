import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const PromotionPopup = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if popup has been shown before (optional)
    const hasSeenPopup = localStorage.getItem("hasSeenPopup");
    if (!hasSeenPopup) {
      setIsPopupVisible(true); // Show popup on page load
      localStorage.setItem("hasSeenPopup", "true"); // Mark as shown
    }
  }, []);

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Close the popup
  };

  const handleShopNow = () => {
    navigate('/ebooks')
  }

  return (
    isPopupVisible && (
      <div style={popupStyles.overlay}>
        <div style={popupStyles.popup}>
          <h2>Special Promotion!</h2>
          <p>Get 50% off on AI eBooks. Limited time offer!</p>
          <button onClick={handleShopNow} style={popupStyles.button}>
            Shop Now
          </button>

          <button onClick={handleClosePopup} style={popupStyles.button}>
            Close
          </button>

        </div>
      </div>
    )
  );
};

const popupStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    background: "#fff",
    padding: "20px",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    margin:'5px'
  },
};

export default PromotionPopup;
