// LoginSignup.js
import React, { useState } from "react";
import "./LoginSignup.css";
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getDatabase, push, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router';

const ForgotPassword = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordResetSuccess,setPasswordResetSuccess] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
     setPasswordResetSuccess(true)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  return (
   <div id="body">
     <div className="auth-container">
     <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:10}}>
          <img src={require('./src/images/splash-icon.png')} style={{width:100,height:100}} alt="" />
          <h1 style={{color:'whitesmoke'}}>ChatGPT AI X</h1>
          </div>
      <div className="auth-card">
     
        {/* <h2>{isLogin ? "Login" : "Sign Up"}</h2> */}
        <form onSubmit={handlePasswordReset}>
        {error && <Alert variant="danger">{error}</Alert>}
        {passwordResetSuccess && <Alert variant="success">Password reset link has been emailed. Check your email and click on the link to reset password</Alert>}
          <div className="input-group">
            <label>Email</label>
            <input 
            style={{fontSize:16}}
            type="email" 
            placeholder="Enter your email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            />
          </div>
         
          <button type="submit" className="auth-button">
            {loading ? "Resetting.." : "Reset"}
          </button> 

        </form>
       {<p className="toggle-text">
          {"Need to login?"}{" "}
          <span onClick={() => navigate('/login')} className="toggle-link">
            Log In
          </span>
        </p>}
      </div>
    </div>
   </div>
  );
};

export default ForgotPassword;