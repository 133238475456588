import React, { useState } from "react";
import "./FlashSaleTop.css";

const FlashSaleTop = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="flash-sale">
        <span className="message">🎉 Flash Sale: 50% Off! 🎉</span>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
      </div>
    )
  );
};

export default FlashSaleTop;
