import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CreateSubscription.css';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router';
import NavbarComponent from './NavbarComponent';

// Load Stripe.js with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionRenewal = ({ priceId, userId }) => {
    const auth = getAuth();
  const [email, setEmail] = useState(auth?.currentUser?.email);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }


    // Create a payment method using Stripe Elements
    const cardElement = elements.getElement(CardElement);
     // Create a payment method
     const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { email },
      });

    try {
      // Send the payment token, priceId, and userId to the backend to create a subscription
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-subscription`, {
        paymentMethodId:paymentMethod.id,
        priceId,
        email
      });
//console.log(response.status, response.data.id);
         let subscriptionId = response?.data?.id;
        // console.log(subscriptionId);
        set(ref(getDatabase(),`users/${getAuth()?.currentUser?.uid}`),{
            uid:getAuth()?.currentUser?.uid,
            email,
            date:new Date().toString(),
            subscribed:true,
            subscriptionId:subscriptionId,
            paymentMethodId:paymentMethod.id
          })
         navigate(`/`)
       // alert('Subscription successful!');
    } catch (error) {
      setErrorMessage('Error during subscription creation.');
      console.error(error);
    }

    setLoading(false);
  };

  

  return (
   <>
   <NavbarComponent />
   <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'100vw',height:'100vh'}}>

<div style={{display:'flex',flexDirection:'column',justifyContent:'center',}}>
    <img src={require('./src/images/splash-icon.png')} style={{width:45,height:45, alignSelf:'center'}} />
    </div>

{/* <h1 class="promo-header">Get ChatGPT Plus</h1>

<p style={{padding:5,textAlign:'center'}}>More messages on our powerful model with premium features</p> */}


<ul className="premium-features">
        <li>Up to 5x more messages on GPT 4o and access to GPT 4</li>
        <li>Higher limits for photo and file uploads, web browsing, image generation, and data analysis</li>
        <li>Access to Advanced Voice Mode</li>
        <li>Use, create and share custom GPTs</li>
        <li>Early access to new features</li>
    </ul>
    <form 
    style={{width:380}}
    onSubmit={handleSubmit}>
     {!email && <input
      className='input email-input'
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email Address"
        required
        style={{  
            fontSize: '16px',
            color: '#424770',
            fontFamily: 'Arial, sans-serif',
            '::placeholder': {
              color: '#aab7c4',
            }, 
            width:'100%',
            borderWidth:0.3,
            borderRadius:10
          }}
      />}
        <div
       className={`card-element-wrapper ${isFocused ? 'focused' : ''} ${
        isError ? 'error' : ''
      }`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '12px',
          backgroundColor: '#fff',
          width: '100%',
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Arial, sans-serif',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      {errorMessage && <p className="error p">{errorMessage}</p>}
      <button className='button' type="submit" disabled={loading}>
        {loading ? 'Processing...' : 'Subscribe Now'}
      </button>

      <p style={{fontSize:12,textAlign:'center'}}>Auto-renews for $5.99/month until canceled</p>

      
    </form>
    
   </div>
   </>
  );
};

function RenewSubscription() {
  // Example: priceId and userId would typically be fetched dynamically
  const [priceId, setPriceId] = useState(process.env.REACT_APP_STRIPE_PRICE_ID); // Replace with your Stripe price ID
  const userId = 'user123';  // Replace with actual user ID from your app
  // console.log(priceId);

  return (
    <Elements stripe={stripePromise}>
       <SubscriptionRenewal priceId={priceId} userId={userId} />
    </Elements>
  );
}

export default RenewSubscription;
