import React, { useEffect } from 'react';
import './styles.css';
import CopyTextComponent from './CopyTextComponent';

const CodeStyler = (props) => {
  useEffect(() => {
    // Function to style paragraphs with code
    const styleParagraphs = () => {
      const paragraphs = document.querySelectorAll('p');
      paragraphs.forEach((paragraph) => {
        const originalText = paragraph.innerHTML;

        // Regex to detect inline code within backticks (e.g., `code`)
        const codeRegex = /`([^`]+)`/g;

        // Replace matched code with styled spans
        let styledText = originalText.replace(
          codeRegex,
          '<span class="code-block">$1</span>'
        );

          // Regex to match inline code like `code`
          const codeRegexP = /`([^`]+)`/g;

          // Function to replace matched code with styled spans
          const getStyledText = (t) => {
            return t.replace(
              codeRegexP,
              (match, p1) => `<span class="code-block">${p1}</span>`
            );
          };

            // Styled text with code blocks processed
  const styledParagraphText = getStyledText(styledText);
  // Function to replace matched code with styled spans
  const getStyledHeader = (t) => {
    return t.replace(
      codeRegex,
      (match, h1) => `<span class="code-block">${h1}</span>`
    );
  };

  styledText = getStyledHeader(styledParagraphText)

        // Update paragraph content
        paragraph.innerHTML = styledText;
      });
    };

    styleParagraphs();
  }, []);

  return (
    <CopyTextComponent role={props.role} text={props.text} />
  );
};

export default CodeStyler;