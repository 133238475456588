// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA3OWR04yHIy92wjrNymMVwB78Lv5V4DYs",
  authDomain: "chat-gptai-12d91.firebaseapp.com",
  databaseURL: "https://chat-gptai-12d91-default-rtdb.firebaseio.com",
  projectId: "chat-gptai-12d91",
  storageBucket: "chat-gptai-12d91.firebasestorage.app",
  messagingSenderId: "668139814615",
  appId: "1:668139814615:web:073e8544a9d18c3706fa92",
  measurementId: "G-YN3WXJ1EFM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;