import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
// import './App.css';
import TypingText from './TypingText';
import Navbar from './NavbarComponent';
import './ChatInput.css';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { getDatabase, onValue, push, ref } from 'firebase/database';
import { FaArrowUp } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import './firebase';
import PromotionPopup from './ebooks/PromotionPopup';

const ChatBot = () => {

      const [messages, setMessages] = useState([]); // To store chat history
      const [input, setInput] = useState(''); // To store user input
      const [status, setStatus] = useState(null);
      const [currentUser,setCurrentUser] = useState(null);
    
      let userId = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : "guestuser"; 
    
      const uid = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : null;
    
      const navigate = useNavigate();
        const messagesEndRef = useRef(null);
    
      function generateRandomCode() {
        return Math.floor(1000000000 + Math.random() * 9000000000).toString();
      }

    const [userInput, setUserInput] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your OpenAI API key
  
    const handleSend = async (e) => {
        e.preventDefault();
      if (!userInput.trim()) return;
  
      const userMessage = { role: 'user', content: userInput };
      setChatLog((prevLog) => [...prevLog, userMessage]);
      setUserInput('');
      setLoading(true);
  
      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [...chatLog, userMessage],
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
          }
        );
  
        const assistantMessage = response.data.choices[0].message;
        setChatLog((prevLog) => [...prevLog, assistantMessage]);
      } catch (error) {
        console.error('Error communicating with OpenAI:', error);
        const errorMessage = { role: 'assistant', content: 'Sorry, something went wrong.' };
        setChatLog((prevLog) => [...prevLog, errorMessage]);
      } finally {
        setLoading(false);
        await checkUsage(userId);
      }
    };

      const checkUsage = async (userId) => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-usage`, { userId });
          setStatus(response.data);
        } catch (error) {
          setStatus({ allowed: false, message: error.response?.data?.error || 'An error occurred' });
        }
      };

 
  return (
    <>
    <PromotionPopup />
    <Navbar />
    <div style={{height:50,fontSize:16,fontWeight:'normal'}}></div>
    <Container className="py-4 chat-container" fluid>
      <Row className="justify-content-md-center">
        <Col md={12}>
          {/* <h3 className="text-center mb-4">ChatGPT AI Pro</h3> */}

          <ListGroup className="chat-box mb-4" style={{ overflowY: 'scroll' }}>
           
            {chatLog.map((msg, index) => (
              <ListGroup.Item
              style={{
                border:0,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                justifyContent:msg.role === 'user' ? 'flex-end' : 'flex-start'}}
                key={index}
                className={msg.role === 'user' ? 'text-end' : 'text-start'}
              >
                {msg.role !== 'user' && <img src={require('./src/images/chatGPT-black.png')} style={{width:24,height:24,marginTop:17,marginRight:5}} alt="" /> }
               {msg.role === 'user' ? <TypingText role={msg.role} fullText={msg.content} typingSpeed={10} /> : <TypingText role={msg.role} fullText={msg.content} typingSpeed={10} />}
               {msg.role === 'user' && <FaRegCircleUser style={{marginTop:22,marginLeft:10}} /> }
              </ListGroup.Item>
            ))}

            {
              chatLog?.length === 0 && (
                <ListGroup.Item
                style={{
                  border:0,
                  backgroundColor:'white',
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'flex-start'}}
                  key={'hi'}
                  className={'text-start'}
                >
                  <img src={require('./src/images/chatGPT-black.png')} style={{width:24,height:24,marginTop:17,marginRight:5}} alt="" /> 
                  <TypingText role={'assistant'} fullText={'How Can I Help You?'} typingSpeed={20} />
                </ListGroup.Item>
              )
            }

            <div style={{height:50}}></div>
      
        <div ref={messagesEndRef} />
          </ListGroup>

           <form 
           onSubmit={handleSend}>
           <div className="chat-input-container">
            <input
            style={{fontSize:16}}
                type="text"
                className="chat-input"
                placeholder="Message ChatGPT"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                required
            />
            <button 
            disabled={loading}
            className="send-button" >
            <FaArrowUp />
            </button>
            </div>
           </form>

           
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ChatBot;