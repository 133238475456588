import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import './App.css';
import TypingText from './TypingText';
import Navbar from './NavbarComponent';
import './ChatInput.css';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { getDatabase, onValue, ref } from 'firebase/database';
import { FaArrowUp, FaDownload } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";

const ImageGenerator = () => {
  const [messages, setMessages] = useState([]); // To store chat history
  const [input, setInput] = useState(''); // To store user input
  const [loading, setLoading] = useState(false); // For loading indicator
  const [status, setStatus] = useState(null);
  const [currentUser,setCurrentUser] = useState(null);

  let userId = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : "guestuser"; 

  const uid = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : null;

  const navigate = useNavigate();

  function generateRandomCode() {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  }
  
  //console.log(generateRandomCode()); // e.g., "1234567890"

  const fetchUser = useCallback(async () => {
    try {
      if (uid) {
        onValue(ref(getDatabase(),`users/${uid}`),(userSnap) => {
          setCurrentUser(userSnap.val())
        })
      }else{
        const prevStoredUserId = localStorage.getItem('userId');
        if (prevStoredUserId) {
          userId = JSON.parse(prevStoredUserId)
        }else{
           userId = generateRandomCode();
        localStorage.setItem('userId', JSON.stringify(userId));
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
          userId = JSON.parse(storedUserId)
          console.log('User loaded from localStorage');
        } else {
          userId = "guestuser";
          console.log('No user found in localStorage');
        }
        }
      }
    } catch (error) {
      console.log(error)
    }
  },[currentUser])

  useEffect(() => {
    fetchUser()
  },[])
  

  const checkUsage = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-usage`, { userId });
      setStatus(response.data);
    } catch (error) {
      setStatus({ allowed: false, message: error.response?.data?.error || 'An error occurred' });
    }
  };

  const messagesEndRef = useRef(null);

    // Function to scroll to the bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };

      // Call scrollToBottom whenever messages change
      useEffect(() => {
        scrollToBottom();
      }, [messagesEndRef]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!input.trim()) return;

    if(status?.message === 'Request limit reached for this month' && !uid) {
      navigate('/login');
    }

    if (status?.message === 'Request limit reached for this month' && uid && !currentUser?.subscribed) {
      navigate(`/subscription/${uid}`)
    }
    // setMessages([...messages,{content:input,role:'user'}])
    const userMessage = { role: 'user', content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    // const options = {
    //     method: 'POST',
    //     url: 'https://open-ai21.p.rapidapi.com/texttoimage2',
    //     headers: {
    //       'x-rapidapi-key': '4f9912956bmsh7e1266f4e27ecb5p170274jsncfedc625eddb',
    //       'x-rapidapi-host': 'open-ai21.p.rapidapi.com',
    //       'Content-Type': 'application/json'
    //     },
    //     data: {text: input}
    //   };

    const options = {
      method: 'POST',
      url: 'https://chatgpt-42.p.rapidapi.com/texttoimage',
      headers: {
        'x-rapidapi-key': '4f9912956bmsh7e1266f4e27ecb5p170274jsncfedc625eddb',
        'x-rapidapi-host': 'chatgpt-42.p.rapidapi.com',
        'Content-Type': 'application/json'
      },
      data: {
        text: input,
        width: 512,
        height: 512
      }
    };

      
      try {
          const response = await axios.request(options);
          // console.log(response.data);
          const userMessage = { role: 'assistant', content: input, ...response.data };
        setMessages((prev) => [...prev, userMessage]);
      } catch (error) {
          console.error(error);
      }
    finally {
      setLoading(false);
      setInput('');
      await checkUsage(userId);
    }
  };


  // console.log('status message: ', status?.message);
   console.log('status remaining: ', status?.remaining);
  // console.log('messages',messages);

  return (
    <>
    <Navbar />
    <div style={{height:50,fontSize:16,fontWeight:'normal'}}></div>
    <Container className="py-4 chat-container" fluid>
      <Row className="justify-content-md-center">
        <Col md={12}>
          {/* <h3 className="text-center mb-4">ChatGPT AI Pro</h3> */}

          <ListGroup className="chat-box mb-4" style={{ height: '650px', overflowY: 'scroll' }}>
            {messages.map((msg, index) => (
              <ListGroup.Item
              style={{
                border:0,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                justifyContent:msg.role === 'user' ? 'flex-end' : 'flex-start'}}
                key={index}
                className={msg.role === 'user' ? 'text-end' : 'text-start'}
              >


{msg.role !== 'user' && <img src={require('./src/images/chatGPT-black.png')} style={{width:24,height:24,marginTop:17,marginRight:5}} alt="" /> }
               {msg.role === 'user' ? <TypingText fullText={msg.content} typingSpeed={10} /> : <>
               <img src={msg?.generated_image} style={{width:320,height:'auto'}} alt='img' />
                <a style={{display:'block'}} href={msg?.generated_image} download={msg?.generated_image?.slice(-12)}><FaDownload style={{margin:5}} /></a>
               </>}
               {msg.role === 'user' && <FaRegCircleUser style={{marginTop:10}} /> }
              </ListGroup.Item>
            ))}
      
        <div ref={messagesEndRef} />
          </ListGroup>
           <form 
           onSubmit={handleSendMessage}>
           <div className="chat-input-container">
            <input
            style={{fontSize:16}}
                type="text"
                className="chat-input"
                placeholder="Create A Dog"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                required
            />
            <button 
            disabled={loading}
            className="send-button" >
            <FaArrowUp />
            </button>
            </div>
           </form>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ImageGenerator;