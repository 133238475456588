import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { jsPDF } from "jspdf";
import { Alert } from "react-bootstrap";
import { TbFileTypeDocx } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

const EditableTextArea = ({ initialText, rows, cols, onChange, handleDownload, generateDocx }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [isSaved,setIsSaved] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    setIsSaved(true);
  };
  const handleChange = (event) => onChange(event.target.value);

  const navigate = useNavigate();

  return (
    <div style={{ width: "100%", maxWidth: "600px", margin: "20px auto" }}>
        {isSaved && <Alert variant="success">Saved!</Alert>}
      {isEditing ? (
        <>
          <textarea
            value={initialText}
            onChange={handleChange}
            rows={rows}
            cols={cols}
            style={{
              width: "100%",
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
          <button
            onClick={handleSaveClick}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </>
      ) : (
        <>
         <div style={{width:'100%',height:600,overflow:'scroll'}}>
         <p
            style={{
              whiteSpace: "pre-wrap", // To preserve line breaks
              padding: "10px",
              backgroundColor: "#f9f9f9",
              border: "1px solid #ddd",
              borderRadius: "4px",
              minHeight: `${rows * 1.5}em`, // Approximate textarea height
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
            }}
          >
            {initialText}
          </p>
         </div>
         
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
         <button
            onClick={() => navigate('/')}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#808080",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Go back
          </button>

         <button
            onClick={handleEditClick}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#8fbc8f",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Edit
          </button>

          <button
            onClick={handleDownload}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save as <FaFilePdf />
          </button>

          <button
            onClick={generateDocx}
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save as <TbFileTypeDocx/>
          </button>

         </div>

        </>
      )}
    </div>
  );
};

function Edit(){
    const navigate = useNavigate();
    const location = useLocation();

    const [initialText,setinitialText] = useState(location.state.content);

    function onChange(text){
        setinitialText(text)
    }

    const handleDownload = () => {
        let text = initialText;
        const doc = new jsPDF();
          // Set font size
      doc.setFontSize(14); // Set the font size to 12

       // Add a normal text paragraph
  doc.setFontSize(12);
  doc.setFont("times", "normal");  
        const margin = 10; // Left and right margins
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const usableWidth = pageWidth - 2 * margin;
        const lineHeight = 10; // Vertical space between lines
      
        // Split text into lines
        const lines = doc.splitTextToSize(text, usableWidth);
      
        let cursorY = margin; // Initial vertical position for text
      
        lines.forEach((line) => {
          if (cursorY + lineHeight > pageHeight - margin) {
            // Add a new page if text goes beyond the current page
            doc.addPage();
            cursorY = margin; // Reset vertical position
          }
          doc.text(line, margin, cursorY);
          cursorY += lineHeight; // Move to the next line
        });
    
         // Create an instance of jsPDF
      
      // Add Date and Time to the PDF (current time)
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString(); // or custom format
      // Add a normal text paragraph
    doc.setFontSize(14);
    doc.setFont("times", "normal");
    doc.text(`Generated on: ${formattedDate}`, 20, 30);
      // Save the PDF
      doc.save("updated_document.pdf");
    
      };




      const generateDocx = () => {
        let text = initialText;
        // Page Setup
        const doc = new Document({
          sections: [
            {
              properties: {
                page: {
                  margin: {
                    top: 720, // 1 inch
                    bottom: 720, // 1 inch
                    left: 720, // 1 inch
                    right: 720, // 1 inch
                  },
                },
              },
              children: text.split("\n").map((line) => 
                new Paragraph({
                  children: [new TextRun({ text: line.trim(), font: "Arial", size: 24 })], // Font size 12pt
                  alignment: "left", // Align text to the left
                })
              ),
            },
          ],
        });
      
        // Generate and Save the Document
        Packer.toBlob(doc).then((blob) => {
          saveAs(blob, "document.docx");
        });
      };



    return (
        <EditableTextArea
        initialText={initialText}
        onChange={onChange}
        handleDownload={handleDownload}
        generateDocx={generateDocx}
        rows={20}
        cols={50}
      />
    )
}

export default Edit;
