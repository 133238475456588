import React from "react";
import "./FlashSale.css";
import { useNavigate } from "react-router";

const FlashSale = () => {
    const navigate = useNavigate();

    function navigateToEbookStore(){
        navigate('/ebooks');
    }
  return (
    <div className="flash-sale-container">
      <div className="sale-banner">
        <h1>Flash Sale!</h1>
        <p>50% off for the next 24 hours only!</p>
      </div>

      <div className="sale-details">
        <p className="scarcity">Only <span>10</span> copies left at this price!</p>
        <div className="bundle-deal">
          <h3>Bundle Offer</h3>
          <p>Buy 2, Get 1 Free!</p>
        </div>
      </div>

      <button onClick={navigateToEbookStore} className="cta-button">Get Your Deal Now</button>
    </div>
  );
};

export default FlashSale;
