import React from "react";
import "./BookItem.css";

const BookItem = ({ book }) => {
  const { title, authors, imageLinks, previewLink } = book.volumeInfo;

  return (
    <div className="book-item">
      <img
        src={imageLinks?.thumbnail || "https://via.placeholder.com/128x193"}
        alt={title}
      />
      <div className="book-details">
        <h3>{title}</h3>
        <p>{authors?.join(", ") || "Unknown Author"}</p>
        <a href={previewLink} target="_blank" rel="noopener noreferrer">
          Preview
        </a>
      </div>
    </div>
  );
};

export default BookItem;
