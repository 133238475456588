import React, { useState } from "react";
import { useNavigate } from "react-router";
import './firebase';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // Replace with your Stripe public key

const SubscriptionForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSubscribed,setIsSubscribed] = useState(false);

  const [priceId, setPriceId] = useState('price_1QRKaeHvmKXk4OG9m0iEGP2z'); // Replace with your Stripe price ID
  const [subscriptionId, setSubscriptionId] = useState('');
  const [message, setMessage] = useState('');

  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  const stripe = useStripe();
  const elements = useElements();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Step 1: Authenticate user
      let userCredential;
      try {
        // Try signing in the user
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        navigate('/');
        return;
      } catch (err) {
        if (err.code === "auth/user-not-found") {
          // If user does not exist, create an account
          userCredential = await createUserWithEmailAndPassword(auth, email, password);
          set(ref(getDatabase(),`users/${userCredential?.user?.uid}`),{
            uid:userCredential?.user?.uid,
            email,
            subscibed:false,
            date:new Date().toString()
          })
        } else {
          throw err;
        }
      }

      const user = userCredential.user;

      // Step 2: Check if the user is already subscribed
      onValue(ref(getDatabase(),`users/${user.uid}`),(userSnapShot) => {
        if (userSnapShot.exists()) {
            setIsSubscribed(userSnapShot?.val()?.subscibed);
        }
      })
      
       if (isSubscribed) {
        // Redirect to chatroom if already subscribed
       navigate("/");
       return;
       }

      // Step 3: If not subscribed, create a subscription
      if (!stripe || !elements) return;

      const cardElement = elements.getElement(CardElement);
        // Create a payment method
        const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { email },
          });
        // Call backend to create subscription
        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-subscription`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email,
              paymentMethodId: paymentMethod.id,
              priceId,
            }),
          });
    
          const data = await response.json();
          setSubscriptionId(data.id);
          setMessage('Subscription created successfully!');

      navigate('/');
      return;

    } catch (err) {
      console.error(err);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", padding: "20px", textAlign: "center" }}>
      <h2>Subscribe to Our Service</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ width: "100%", padding: "10px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ width: "100%", padding: "10px" }}
          />
        </div>

        <div style={{ marginBottom:"10px"}}>
        <div
       className={`card-element-wrapper ${isFocused ? 'focused' : ''} ${
        isError ? 'error' : ''
      }`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '12px',
          backgroundColor: '#fff',
          width: '100%',
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Arial, sans-serif',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
        </div>
        <button type="submit" disabled={loading} style={{ width: "100%", padding: "10px" }}>
          {loading ? "Processing..." : "Subscribe"}
        </button>
      </form>
    </div>
  );
};

const Subscription = () => {
    return (
      <Elements stripe={stripePromise}>
        <SubscriptionForm />
      </Elements>
    );
  };
  
  export default Subscription;