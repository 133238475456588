import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './firebase';
import { Routes, Route } from 'react-router';
import ChatRoom from './ChatRoom';
import Subscription from './Subscription';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Profile from './Profile';
import RequestChecker from './RequestChecker';
import ImageGenerator from './ImageGenerator';
import LoginSignup from './LoginSignup';
import ForgotPassword from './ForgotPassword';
import History from './History';
import Privacy from './Privacy';
import SubscriptionForm from './SubscriptionForm';
import CreateSubscription from './CreateSubsctiption';
import SubscriptionManager from './SubscriptionManager';
import AdminDashboard from './AdminDashboard';
import UserProfile from './UserProfile';
import Edit from './Edit';
import EbookStore from './ebooks/EbookStore';
import DownloadBooks from './ebooks/books/DownloadBooks';
import AddProductForm from './ebooks/AddProductForm';
import GoogleBookAPI from './ebooks/GoogleBookAPI';
import FlashSale from './ebooks/FlashSale';
import ChatBot from './ChatBot';

const GuestPage = () => {

  return (
    <LoginSignup />
  )
}

const NotFound = () => {
  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',height:'100vh',width:'100vw',alignItems:'center'}}>
      <h1>404 | Page Not Found</h1>
      <a href="/"><button className='btn btn-warning'>Go back</button></a>
    </div>
  )
}




const GuestRoutes = () => {
  return (
    <Routes>
       <Route path='/' element={<ChatBot />} />
      <Route path='/login' element={<GuestPage />} />
      <Route path='/forgotpassword' element={<ForgotPassword />} />
      <Route path="/imagegenerator" element={<ImageGenerator />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/subscribe" element={<CreateSubscription />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/edit" element={<Edit />} />
      <Route path="/ebooks" element={<EbookStore />} />
      <Route path="/downloadbooks" element={<DownloadBooks />} />
      <Route path="/addproduct" element={<AddProductForm />} />
      <Route path="*" element={<EbookStore />} />
      <Route path="/search" element={<GoogleBookAPI />} />
      <Route path="/flashsale" element={<FlashSale />} />
      <Route path='/chatbot' element={<ChatBot />} />
    </Routes>
  )
}

const UserRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<ChatBot />} />
      <Route path="/subscription/:id" element={<Subscription />} />
      <Route path="/subscribe" element={<CreateSubscription />} />
      <Route path="/imagegenerator/:id" element={<ImageGenerator />} />
      <Route path='/chatroom' element={<ChatRoom />} />
      <Route path='/profile' element={<UserProfile />} />
      <Route path='/userrequest' element={<RequestChecker />} />
      <Route path='/history' element={<History />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/manage" element={<SubscriptionManager />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/edit" element={<Edit />} />
      <Route path="/ebooks" element={<EbookStore />} />
      <Route path="/downloadbooks" element={<DownloadBooks />} />
      <Route path="/addproduct" element={<AddProductForm />} />
      <Route path="/search" element={<GoogleBookAPI />} />
      <Route path="*" element={<EbookStore />} />
      <Route path="/flashsale" element={<FlashSale />} />
      <Route path='/chatbot' element={<ChatBot />} />
  </Routes>
  )
}

const App = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);


  // State to track if dark mode is enabled
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Load the theme from localStorage when the component mounts
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkMode(savedTheme === 'dark');
    } else {
      // Default to light mode
      setIsDarkMode(false);
    }
  }, []);

  // Update theme in localStorage and on the body element
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  // Toggle dark mode
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
   <>
   { user ? <UserRoutes /> : <GuestRoutes /> }
   </>
  )
}

export default App;