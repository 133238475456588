import React, { useState } from 'react';
import './DownloadBooks.css';
import { useLocation } from 'react-router';
import { Alert } from 'react-bootstrap';

const books = [
  { id: 1, title: 'The Great Gatsby', author: 'F. Scott Fitzgerald', downloadUrl: '#!' },
  { id: 2, title: '1984', author: 'George Orwell', downloadUrl: '#!' },
  { id: 3, title: 'To Kill a Mockingbird', author: 'Harper Lee', downloadUrl: '#!' },
  { id: 4, title: 'Pride and Prejudice', author: 'Jane Austen', downloadUrl: '#!' },
];

const DownloadBooks = () => {
    const location = useLocation();
    const [cart,setCart] = useState([...location.state.cart]);

  return (
    <div className="book-list-container">
      <h1 className="heading">Purchased eBooks</h1>
      <Alert variant='success' dismissible>Purchase Successful</Alert>
      <ul className="book-list">
        {cart?.map((book) => (
          <li key={book.id} className="book-item">
            <div className="book-info">
                <img src={book.image} style={{width:'100%',height:'auto',alignSelf:'center'}} alt="" />
              <h2 className="book-title">{book.title}</h2>
              <p className="book-author">Language: {book.language}</p>
              <a href={book.url} download className="download-button">
              Download
            </a>
            </div>
           
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DownloadBooks;
