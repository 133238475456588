import React from 'react';
import './CardDetails.css';

const CardDetails = ({ cardBrand, last4, onRemove }) => {
  // Map card brands to their respective images
  const brandLogos = {
    visa: 'https://chatgptaipro.s3.us-east-1.amazonaws.com/1.png',
    mastercard: 'https://chatgptaipro.s3.us-east-1.amazonaws.com/2.png',
    amex: 'https://chatgptaipro.s3.us-east-1.amazonaws.com/3.png',
    discover: 'https://chatgptaipro.s3.us-east-1.amazonaws.com/4.png',
    default: 'https://chatgptaipro.s3.us-east-1.amazonaws.com/5.png',
  };

  const cardIcon = brandLogos[cardBrand?.toLowerCase()] || brandLogos.default;

  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
        <div className="card-container">
      <div className="card-details">
        <img src={cardIcon} alt={`${cardBrand} logo`} className="card-icon" />
        <div className="card-info">
          <div className="card-brand">{cardBrand || 'Unknown Brand'}</div>
          <div className="card-last4">•••• {last4}</div>
        </div>
      </div>
      {onRemove && (
        <button className="remove-button" onClick={onRemove}>
          Remove
        </button>
      )}
    </div>
    </div>
  );
};

export default CardDetails;