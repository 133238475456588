import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref, update } from 'firebase/database';
import RenewSubscription from './RenewSubscription';
import './SubscriptionManager.css';
import NavbarComponent from './NavbarComponent';

function SubscriptionManager() {
  const [subscriptionId, setSubscriptionId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const auth = getAuth();

  // Replace this with the actual user ID (e.g., from Firebase Auth)
  const [userId ]= useState(auth?.currentUser?.uid); 
  const [priceId, setPriceId] = useState(process.env.REACT_APP_STRIPE_PRICE_ID);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        onValue(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),(userSnapsot) => {
          if (userSnapsot.exists() && userSnapsot.val().subscriptionId) {
              setSubscriptionId(userSnapsot.val().subscriptionId);
              setLoading(false);
          }
      })
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch subscription data.');
        setLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const handleCancelSubscription = async () => {
    setIsProcessing(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subscriptionId }),
      });

      const data = await response.json();
      console.log(data);

      if (response.status === 200) {
        update(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
          subscribed:false,
          subscriptionId:""
        })
        setSubscriptionId(null); // Clear the subscription state after cancellation
        alert('Subscription cancelled successfully.');
      }
    } catch (err) {
      setError('Failed to cancel subscription.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRenewSubscription = async () => {
    setIsProcessing(true);

    if (subscriptionId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/renew-subscription`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ subscriptionId }),
        });
  
        const data = await response.json();
        console.log(data);
       if (response.status === 200) {
        update(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
          subscribed:true,
          subscriptionId:subscriptionId
        })
       }
      } catch (err) {
        setError('Failed to initiate subscription renewal.');
      } finally {
        setIsProcessing(false);
      }
    }else{
      alert('unable to renew, because subscriptionId undefined')
    }
  };

  if (loading) return <div>Loading...</div>;

  if (error) return <div>{error}</div>;

  return (
    <>
    <NavbarComponent />
    <div className="subscription-container">
      {subscriptionId ? (
        <div className="subscription-info">
          <h3>Your Subscription</h3>
          <p>Status: <span className="status">Active</span></p>
          <p>Plan: <span className="plan-id">ChatGPT Plus</span> </p>

          {subscriptionId ? (
            <div className="subscription-actions">
              <button
                onClick={handleCancelSubscription}
                disabled={isProcessing}
                  className="cancel-button"
              >
                {isProcessing ? 'Processing...' : 'Cancel Subscription'}
              </button>
            </div>
          ) : (
            <div className="subscription-actions">
              <button
                onClick={handleRenewSubscription}
                disabled={isProcessing}
                  className="renew-button"
              >
                {isProcessing ? 'Processing...' : 'Renew Subscription'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <RenewSubscription />
      )}
    </div>
    </>
  );
}

export default SubscriptionManager;
