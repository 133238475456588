import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import CardDetails from './CardDetails';

const FetchPaymentMethod = (props) => {
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);
  const [error, setError] = useState('');

  const handleFetchPaymentMethod = useCallback(async () => {
    setError('');
    setPaymentMethodDetails(null);

    if (!props.paymentMethodId?.trim()) {
      setError('Please enter a Payment Method ID');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment-method`, {
        paymentMethodId:props.paymentMethodId,
      });
      setPaymentMethodDetails(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Error fetching payment method details');
    }
  })

  // console.log(paymentMethodDetails);

  useEffect(() => {
    handleFetchPaymentMethod()
  },[])

 async function handleRemove(cardId){
   // console.log('ready to remove a card',cardId)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/remove-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentMethodId:cardId }),
      });

      const result = await response.json();

      if (result.success) {
        alert('Payment method removed successfully!');
      } else {
        alert('Failed to remove payment method.');
      }
    } catch (error) {
      console.error(error);
      alert('An error occurred.');
    }
  
  }

  

  return (
    <CardDetails
          key={paymentMethodDetails?.id}
          cardBrand={paymentMethodDetails?.card?.brand}
          last4={paymentMethodDetails?.card?.last4}
          onRemove={async () => await handleRemove(paymentMethodDetails?.id)}
        />
  );
};

const styles = {
  container: { padding: '20px', textAlign: 'center', maxWidth: '600px', margin: '0 auto' },
  title: { fontSize: '24px', marginBottom: '20px' },
  input: { padding: '10px', fontSize: '16px', width: '100%', marginBottom: '10px' },
  button: { padding: '10px 15px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' },
  error: { color: 'red', marginTop: '10px' },
  result: { marginTop: '20px', textAlign: 'left', backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '8px' },
};

export default FetchPaymentMethod;