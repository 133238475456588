import React, { useCallback, useEffect, useState } from 'react';
import './chatstyles.css';
import axios from 'axios';

const ChatParagraph = ({ text }) => {
    const headerRegex = /^(#\s.*)$/gm; 

    const [language, setLanguage] = useState("");

   // console.log(language);

    const updateLanguage = useCallback(() => {
       // Get the browser's preferred language
       const userLanguage = navigator.language || navigator.userLanguage;
       setLanguage(userLanguage);
    },[language])

    useEffect(() => {
     updateLanguage()
    }, []);
  

    // Header style (darker)
const headerStyle = {
    backgroundColor: '#2c3e50', // Dark background for headers
    color: '#000000', // Lighter text color
    padding: '10px',
    borderRadius: '5px',
    fontWeight: 'bold',
  };

  async function translator(from,to,query){

  const encodedParams = new URLSearchParams();
  encodedParams.set('q', query);

const options = {
  method: 'POST',
  url: 'https://google-translate1.p.rapidapi.com/language/translate/v2/detect',
  headers: {
    'x-rapidapi-key': 'cf85813b39msh5f6f7ab602f9fbep1a9fc2jsnbec04e0e2187',
    'x-rapidapi-host': 'google-translate1.p.rapidapi.com',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept-Encoding': 'application/gzip'
  },
  data: encodedParams,
};

try {
	const response = await axios.request(options);
	console.log(response.data.data.detections[0]);
  
} catch (error) {
	console.error(error);
}
  }


  return (
    <div>
      {text.split('\n').map((line, index) => {
          // Check if the line matches a header (starts with '#')
          if (line.startsWith('# ')) {
            return `<h1>${line.slice(2)}</h1>`;
        }else if (line.match(headerRegex)) {
        return (
          <h2 key={index} 
          style={{
            fontWeight:'bold',
            fontSize:32,
            lineHeight:1.3,
            textAlign:'center',
            margin:20,
            color:'#000000', // #222
            textTransform:'capitalize',
            letterSpacing:'0.8px'

          }}>{line}</h2>  // Apply darker header style
        );
      }
       // return <DynamicParagraphs key={index} paragraphs={line.split('\n')} />
       return <p style={{
        fontSize:18,
        textAlign:'justify',
        color:'#000000', // #333
        fontFamily:'Georgia Times New Romain, serif',
        lineHeight:1.6,
        letterSpacing:'0.5px',

      }} key={index}>{line}</p>
      })}
    </div>
  );
};

export default ChatParagraph;