import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const RequestChecker = () => {
    const auth = getAuth()
    const [uid,setUid] = useState(auth?.currentUser?.uid);
  const [userId, setUserId] = useState(uid);
  const [status, setStatus] = useState(null);

  const checkUsage = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-usage`, { userId });
      setStatus(response.data);
      console.log('status:',response.data)
    } catch (error) {
      setStatus({ allowed: false, message: error.response?.data?.error || 'An error occurred' });
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Check Free Usage</h1>
      <input
        type="text"
        placeholder="Enter User ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        style={{ padding: '10px', fontSize: '16px', width: '300px', marginBottom: '10px' }}
      />
      <button onClick={checkUsage} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
        Check Usage
      </button>
      {status && (
        <div style={{ marginTop: '20px' }}>
          <h3>Status: {status.message}</h3>
          {status.allowed && <p>Remaining Requests: {status.remaining}</p>}
        </div>
      )}
    </div>
  );
};

export default RequestChecker;