import React, { useState, useEffect } from 'react';
import CodeStyler from './CodeStyler';

const TypingText = ({ fullText, typingSpeed, role }) => {
  const [displayedText, setDisplayedText] = useState('');

  // useEffect(() => {
  //   let index = 0;
  //   const interval = setInterval(() => {
  //     if (index < fullText.length) {
  //       setDisplayedText((prev) => prev + fullText[index]);
  //       index++;
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, typingSpeed);

  //   return () => clearInterval(interval);
  // }, [fullText, typingSpeed]);

  useEffect(() => {
    if (!fullText) return; // Ensure fullText is not empty
  
    let index = 0;
    setDisplayedText(fullText[index]); // Reset displayedText when fullText changes
  
    const interval = setInterval(() => {
      if (index < fullText.length-1) {
        setDisplayedText((prev) => prev + fullText[index]); // Add next character
        index++;
      } else {
        clearInterval(interval); // Stop when done
      }
    }, typingSpeed);
  
    return () => clearInterval(interval); // Cleanup interval
  }, [fullText, typingSpeed]);

//   return (
//     <p style={styles.text}>{displayedText}</p>
//   );
return (
    <CodeStyler role={role} text={displayedText} />
)
};

const styles = {
  container: {
    padding: 10,
  },
  text: {
    fontSize: 16,
    color: '#000',
  },
}

export default TypingText;