import React, { useCallback, useEffect, useState } from 'react';
import './AdminDashboard.css';
import { getDatabase, onValue, ref } from 'firebase/database';
import AdminLockScreen from './AdminLockScreen';

// const users = [
//   { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
//   { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
//   { id: 3, name: 'Mark Johnson', email: 'mark.johnson@example.com' },
//   { id: 4, name: 'Emma Brown', email: 'emma.brown@example.com' },
//   { id: 5, name: 'Chris Davis', email: 'chris.davis@example.com' },
// ];

const AdminDashboard = () => {
    const [users,setUsers] = useState([]);
    const [isCorrect,setIsCorrect] = useState(false);

    const fetchUsers = useCallback(() => {
        onValue(ref(getDatabase(),`users`),(usersSnapshot) => {
            let usersArray = [];
            usersSnapshot.forEach((snap) => {
                usersArray.push({
                    id:snap.key,
                    name:snap.val().date,
                    email:snap.val().email,
                    subscribed:snap.val().subscribed
                })
            })
            setUsers(usersArray.reverse());
        })
    },[users])

    useEffect(() => {
        fetchUsers();
    },[])

    function handleOnChange(val){
        setIsCorrect(val)
    }

    function getSubscribedUsers(users){
        let subscribersArray = users.filter(user => user?.subscribed === true);
        return subscribersArray.length;
    }

 function getTotalSum(users){
    let number = 0;
    let totalUsers = getSubscribedUsers(users);
    number = (totalUsers * 5.99);
    return '$' + number.toFixed(2)
 }

  return (
   <>
   {
    isCorrect ? ( <div className='admin-body'>
        <div className="dashboard-container">
        <div className="header">
          <h1>Admin Dashboard</h1>
        </div>
        
        <div>
        <div className="stats">
          <div className="stat-box">
            <h2>Total Users</h2>
            <p style={{color:'white'}}>{users.length}</p>
          </div>
        </div>
          
        <div className="stats">
          <div className="stat-box-subscribers">
            <h2>Subscribers</h2>
            <p style={{color:'white'}}>{getSubscribedUsers(users)}</p>
          </div>
        </div>

        <div className="stats">
          <div className="stat-box-price">
            <h2>Price</h2>
            <p style={{color:'white'}}>{'$5.99'}</p>
          </div>
        </div>

        <div className="stats">
          <div className="stat-box-total">
            <h2>Total</h2>
            <p style={{color:'white'}}>{getTotalSum(users)}</p>
          </div>
        </div>

        </div>
  
        <div className="user-list">
          <h2>User Information</h2>
          <table className="user-table responsive-table">
            <thead>
              <tr>
              <th>Email</th>
                <th>UID</th>
                <th>STATUS</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                <td>{user.email}</td>
                  <td>{user.id}</td>
                  <td>{user?.subscribed ? <span style={{color:'green'}}>Subscribed</span> : <span style={{color:'red'}}>Not Subscribed</span>}</td>
                  <td>{user.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>):(<>
      <AdminLockScreen isCorrect={isCorrect} handleOnChange={handleOnChange} />
      </>)
   }
   </>
  );
};

export default AdminDashboard;
