import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
// import './App.css';
import TypingText from './TypingText';
import Navbar from './NavbarComponent';
import './ChatInput.css';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { getDatabase, onValue, push, ref } from 'firebase/database';
import { FaArrowUp } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import './firebase';
import PromotionPopup from './ebooks/PromotionPopup';



const ChatRoom = () => {
  const [messages, setMessages] = useState([]); // To store chat history
  const [input, setInput] = useState(''); // To store user input
  const [loading, setLoading] = useState(false); // For loading indicator
  const [status, setStatus] = useState(null);
  const [currentUser,setCurrentUser] = useState(null);

  let userId = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : "guestuser"; 

  const uid = getAuth()?.currentUser ? getAuth()?.currentUser?.uid : null;

  const navigate = useNavigate();

  function generateRandomCode() {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  }
  
  //console.log(generateRandomCode()); // e.g., "1234567890"

  const fetchUser = useCallback(async () => {
    try {
      if (uid) {
        onValue(ref(getDatabase(),`users/${uid}`),(userSnap) => {
          setCurrentUser(userSnap.val())
        })
      }else{
        const prevStoredUserId = localStorage.getItem('userId');
        if (prevStoredUserId) {
          userId = JSON.parse(prevStoredUserId)
        }else{
           userId = generateRandomCode();
        localStorage.setItem('userId', JSON.stringify(userId));
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
          userId = JSON.parse(storedUserId)
          console.log('User loaded from localStorage');
        } else {
          userId = "guestuser";
          console.log('No user found in localStorage');
        }
        }
      }
    } catch (error) {
      console.log(error)
    }
  },[currentUser])

  useEffect(() => {
    fetchUser()
  },[])
  

  const checkUsage = async (userId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/check-usage`, { userId });
      setStatus(response.data);
    } catch (error) {
      setStatus({ allowed: false, message: error.response?.data?.error || 'An error occurred' });
    }
  };

  const messagesEndRef = useRef(null);

    // Function to scroll to the bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };

      // Call scrollToBottom whenever messages change
      useEffect(() => {
        scrollToBottom();
      }, [messagesEndRef]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!input.trim()) return;

    if(status?.message === 'Request limit reached for this month' && !uid) {
      navigate('/subscribe');
    }

    if (status?.message === 'Request limit reached for this month' && uid && !currentUser?.subscribed) {
      navigate(`/subscription/${uid}`)
    }

    const userMessage = { role: 'user', content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    const options = {
        method: 'POST',
        url: 'https://gpt-4o.p.rapidapi.com/chat/completions',
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          'x-rapidapi-host': process.env.REACT_APP_RAPIDAPI_HOST,
          'Content-Type': 'application/json'
        },
        data: {
          model: 'gpt-4o',
          messages: [
            userMessage
          ]
        }
      };      

    try {
      // Replace with your backend or OpenAI API endpoint
      const response = await axios.request(options)

      const botMessage = { role: 'assistant', content: response.data.choices[0].message.content };
      setMessages((prev) => [...prev, botMessage]);
      if (uid) {
        push(ref(getDatabase(),`history/${uid}`),{
          userMessage,
          botMessage,
          date:new Date().toString()
        })
        console.log('saved into db')
      }
    } catch (error) {
      console.error('Error fetching response:', error);
      const errorMessage = { role: 'assistant', content: 'Sorry, something went wrong.' };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setLoading(false);
      await checkUsage(userId);
    }
  };




  return (
    <>
    <PromotionPopup />
    <Navbar />
    <div style={{height:50,fontSize:16,fontWeight:'normal'}}></div>
    <Container className="py-4 chat-container" fluid>
      <Row className="justify-content-md-center">
        <Col md={12}>
          {/* <h3 className="text-center mb-4">ChatGPT AI Pro</h3> */}

          <ListGroup className="chat-box mb-4" style={{ overflowY: 'scroll' }}>
           
            {messages.map((msg, index) => (
              <ListGroup.Item
              style={{
                border:0,
                backgroundColor:'white',
                display:'flex',
                flexDirection:'row',
                justifyContent:msg.role === 'user' ? 'flex-end' : 'flex-start'}}
                key={index}
                className={msg.role === 'user' ? 'text-end' : 'text-start'}
              >
                {msg.role !== 'user' && <img src={require('./src/images/chatGPT-black.png')} style={{width:24,height:24,marginTop:17,marginRight:5}} alt="" /> }
               {msg.role === 'user' ? <TypingText role={msg.role} fullText={msg.content} typingSpeed={10} /> : <TypingText role={msg.role} fullText={msg.content} typingSpeed={10} />}
               {msg.role === 'user' && <FaRegCircleUser style={{marginTop:22,marginLeft:10}} /> }
              </ListGroup.Item>
            ))}

            {
              messages?.length === 0 && (
                <ListGroup.Item
                style={{
                  border:0,
                  backgroundColor:'white',
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'flex-start'}}
                  key={'hi'}
                  className={'text-start'}
                >
                  <img src={require('./src/images/chatGPT-black.png')} style={{width:24,height:24,marginTop:17,marginRight:5}} alt="" /> 
                  <TypingText role={'assistant'} fullText={'How Can I Help You?'} typingSpeed={20} />
                </ListGroup.Item>
              )
            }

            <div style={{height:50}}></div>
      
        <div ref={messagesEndRef} />
          </ListGroup>

           <form 
           onSubmit={handleSendMessage}>
           <div className="chat-input-container">
            <input
            style={{fontSize:16}}
                type="text"
                className="chat-input"
                placeholder="Message ChatGPT"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                required
            />
            <button 
            disabled={loading}
            className="send-button" >
            <FaArrowUp />
            </button>
            </div>
           </form>

           
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ChatRoom;