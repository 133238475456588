import React, { useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './CardElementStyles.css';
import { getAuth } from 'firebase/auth';
import NavbarComponent from './NavbarComponent';
import { FaCheck } from "react-icons/fa";
import { update, onValue, ref, getDatabase } from 'firebase/database';
import FetchPaymentMethod from './FetchPaymentMethod';
import { ListGroup } from 'react-bootstrap';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = getAuth();
  const [currentUser,setCurrentUser] = useState(auth?.currentUser);
  const [user,setUser] = useState(null);

  const [email, setEmail] = useState(currentUser ? currentUser?.email : '');
  const [priceId, setPriceId] = useState(process.env.REACT_APP_STRIPE_PRICE_ID); // Replace with your Stripe price ID
  const [subscriptionId, setSubscriptionId] = useState(user?.subscriptionId ? user.subscriptionId : '');
  const [message, setMessage] = useState('');

  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading,setLoading] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleChange = (event) => setIsError(event.error ? true : false);

  const fetchUserData = useCallback(() => {
    onValue(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),(userSnapsot) => {
        if (userSnapsot.exists() && userSnapsot.val().subscriptionId) {
            setSubscriptionId(userSnapsot.val().subscriptionId);
            setUser(userSnapsot.val())
        }
    })
  },[user])

  useEffect(() => {
    fetchUserData()
  },[])


  const createSubscription = async () => {
    setLoading(true)
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    try {
      // Create a payment method
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { email },
      });

      // Call backend to create subscription
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          paymentMethodId: paymentMethod.id,
          priceId,
        }),
      });

      const data = await response.json();
      setSubscriptionId(data.id);
      setMessage('Subscription created successfully!');
      setLoading(false);
      // update user
     if (data.id) {
      update(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
        subscribed:true,
        subscriptionId:data?.id,
        paymentMethodId: paymentMethod?.id
      })
     }
    } catch (error) {
      console.error(error);
      setMessage('Failed to create subscription.');
      setLoading(false)
    }
  };

  const cancelSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subscriptionId }),
      });

      const data = await response.json();
      console.log('canceled subscription response data',data);
      update(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
        subscribed:false,
        subscriptionId:""
      })
      setMessage('Subscription canceled successfully!');
      setSubscriptionId('');
    } catch (error) {
      console.error(error);
      setMessage('Failed to cancel subscription.');
    }
  };

  const renewSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/renew-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subscriptionId }),
      });

      const data = await response.json();
      console.log(data);
      update(ref(getDatabase(),`users/${auth?.currentUser?.uid}`),{
        subscribed:true,
        subscriptionId:subscriptionId
      })
      setMessage('Subscription renewed successfully!');
    } catch (error) {
      console.error(error);
      setMessage('Failed to renew subscription.');
    }
  };


const StripeCardInput = () => {
  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '10px' }}>

      <div
       className={`card-element-wrapper ${isFocused ? 'focused' : ''} ${
        isError ? 'error' : ''
      }`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '12px',
          backgroundColor: '#fff',
          width: '100%',
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Arial, sans-serif',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
    </div>
  );
};




  return (
   <>
   <NavbarComponent />
<div  style={{ maxWidth: '400px', margin: '0 auto', padding: '10px', fontSize:16 }}>

<div style={{display:'flex',flexDirection:'column',justifyContent:'center',paddingTop:50}}>
    <img src={require('./src/images/pro.png')} style={{width:75,height:75, alignSelf:'center'}} />
    </div>
  <h3 style={{textAlign:'center',color:subscriptionId?"#8a2be2":"black"}}>
    { subscriptionId ? "ChatGPT Plus" : "Get ChatGPT Plus" }
  </h3>
  <p style={{textAlign:'center'}}>More messages on our most powerful model with premium features</p>
  {message && <p style={{ marginTop: '20px',textAlign:'center' }}>{message}</p>}
  {user?.paymentMethodId ? <FetchPaymentMethod paymentMethodId={user.paymentMethodId} /> : <StripeCardInput />}

<div>
<ListGroup>
      <ListGroup.Item> <FaCheck color='black' /> <small>Up to 5x more messages on GPT 4o and access to GPT 4 </small></ListGroup.Item>
      <ListGroup.Item><FaCheck color='black' /> <small> Higher limits for photo and file uploads, web browsing, image generation, and data analysis</small></ListGroup.Item>
      <ListGroup.Item><FaCheck color='black' /> <small>Access to Advanced Voice Mode</small></ListGroup.Item>
      <ListGroup.Item><FaCheck color='black'/> <small>Use, create and share custom GPTs</small></ListGroup.Item>
      <ListGroup.Item> <FaCheck color='black' /> <small>Early access to new features</small></ListGroup.Item>
    </ListGroup>

</div>

      {subscriptionId ? (
        <div style={{display:'flex',flexDirection:'column',gap:5,padding:5}}>
          <button className='btn btn-danger' onClick={cancelSubscription}>Cancel Subscription</button>
          <button className='btn btn-primary' onClick={renewSubscription}>Renew Subscription</button>
          <p style={{fontSize:12,textAlign:'center'}}>Auto-renews for $5.99/month until canceled</p>
        </div>
      )
      :
      (
        <div style={{display:'flex',flexDirection:'column',marginTop:20}}>
<button 
      className='btn btn-primary'
      onClick={createSubscription} 
      disabled={!stripe}
      >
        {loading ? "Upgrading.." : "Upgrade to Plus"}
      </button>

      <p style={{fontSize:12,textAlign:'center'}}>Auto-renews for $5.99/month until canceled</p>
</div>
      )
    }
</div>

   </>
  );
};

const Subscription = () => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm />
    </Elements>
  );
};

export default Subscription;