import React, { useState } from 'react';
import './AdminLockScreen.css'; // Import CSS styles
import { Alert } from 'react-bootstrap';

const AdminLockScreen = (props) => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');

  const correctPassword = 'Msq1010$'; // Replace with your desired password

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleUnlock = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      props.handleOnChange(true)
    } else {
      setErrorMessage('Incorrect password. Please try again.');
      setPassword('');
      props.handleOnChange(false)
    }
  };

  return (
    <div className="app-container">
      {!isAuthenticated ? (
        <form 
        onSubmit={handleUnlock}
        className="lock-screen">
          <h1>🔒 Lock Screen</h1>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={handlePasswordChange}
            className="password-input"
            required
          />
          <button className="unlock-button">
            Unlock
          </button>
        </form>
      ) : (
        <div className="admin-dashboard">
          <h1>Welcome to the Admin Dashboard</h1>
          <p>Here, you can manage users, settings, and more.</p>
        </div>
      )}
    </div>
  );
};

export default AdminLockScreen;
