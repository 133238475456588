import React from 'react';
import { useLocation } from 'react-router';
import NavbarComponent from './NavbarComponent';
import { Container } from 'react-bootstrap';
import CopyTextComponent from './CopyTextComponent';


export default function History() {
    const location = useLocation();
   // console.log(location.state); // 30

    const ChatStyledText = ({ header, content }) => {
        return (
          <div style={styles.container}>
            <h3 style={styles.header}>{header}</h3>
            <p style={styles.paragraph}>{content}</p>
          </div>
        );
      };
      
      const styles = {
        container: {
          marginBottom: "20px",
          padding: "10px",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9", // Light background to simulate chat-like appearance
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
        header: {
          fontWeight: "bold",
          color: "#2b6cb0", // Highlighted blue color
          marginBottom: "10px",
        },
        paragraph: {
          fontSize: "16px",
          lineHeight: "1.6",
          color: "#333", // Standard readable color for text
        },
      };

      

  return (
    <>
    <NavbarComponent />
    <Container style={{marginTop:100}}>
        <h3 style={{textAlign:'center'}}>{location?.state?.userMessage?.content}</h3>
        <CopyTextComponent role={location?.state?.botMessage?.role} text={location?.state?.botMessage?.content} />
        {/* <ChatStyledText
          header={location?.state?.userMessage?.content}
          content={location?.state?.botMessage?.content}
        /> */}
        <br />
    </Container>
    </>
  )
}
