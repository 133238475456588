import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, updateProfile } from 'firebase/auth'; // Import the updateProfile function for Firebase
import { signOut } from 'firebase/auth';
import './UserProfile.css';
import { getDatabase, onValue, ref, remove } from 'firebase/database';
import NavbarComponent from './NavbarComponent';
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router';

const UserProfile = () => {
    const auth = getAuth();
  const user = auth.currentUser;
  const [displayName, setDisplayName] = useState(user ? user.displayName : '');
  const [history, setHistory] = useState([]);

 const fetchHistory = useCallback(() => {
   onValue(ref(getDatabase(),`history/${auth?.currentUser?.uid}`),(historySnapshot) => {
     let myArr = [];
     historySnapshot.forEach((history) => {
       myArr.push(history)
      // console.log(history.val())
     })
     setHistory(myArr.reverse())
   })
 },[history])

 useEffect(() => {
   if (auth.currentUser) {
     fetchHistory()
   }
 },[])


  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };

  const handleDisplayNameUpdate = useCallback(async () => {
    if (user) {
      try {
        await updateProfile(user, {
          displayName: displayName,
        });
        alert('Display Name updated successfully!');
      } catch (error) {
        console.error('Error updating display name:', error);
      }
    }
  },[user])

  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      alert('Signed out successfully!');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  },[auth])

  const handleClearHistory = () => {
    localStorage.removeItem('browsingHistory');
    setHistory('');
    alert('Browsing history cleared!');
  };

const [isRemoving,setIsRemoving] = useState(false);
const [cleared,setcLeared] = useState(false);

  async function removeData(){
    setIsRemoving(true)
    try {
      if (auth.currentUser) {
        remove(ref(getDatabase(),`history/${auth?.currentUser?.uid}`))
        alert('History has been cleared successfully');
        setIsRemoving(false);
        setcLeared(true);
      }
    } catch (error) {
      console.log(error);
      setIsRemoving(false);
    }
   }

   async function removeHistoryItem(key){
    try {
        remove(ref(getDatabase(),`history/${auth?.currentUser?.uid}/${key}`));
        alert('History Removed');
    } catch (error) {
        console.log(error)
    }
   }

  return (
    <>
    <NavbarComponent />
    <div className='user-profile-container'>
    <div className="user-profile">
        <br />
      <h1>User Profile</h1>

      {user ? (
        <div>
          <div className="profile-info">
            <p>Email: {user.email}</p>
            {/* <p>
              Display Name: 
              <input
                type="text"
                value={displayName}
                onChange={handleDisplayNameChange}
                placeholder="Update your display name"
              />
            </p>
            <button onClick={handleDisplayNameUpdate}>Update Display Name</button> */}
          </div>

          <div className="browsing-history">
            <h3>Browsing History</h3>
            
            <ol>
            {
                history?.map((item,index) => (
                    <li key={index}><Link to={'/history'} state={{key:item.key,...item.val()}}>{item?.val()?.userMessage?.content}</Link>  <IoMdClose color='red' onClick={() => removeHistoryItem(item.key)} /> </li>
                    // <li key={index}>{item?.val()?.userMessage?.content} <IoMdClose color='red' onClick={() => removeHistoryItem(item.key)} /> </li>
                ))
            }
            </ol>
            <p>{history.length === 0 && 'No history available.'}</p>
            <button 
            style={{
                backgroundColor: '#dc3545',
                color: '#fff',
                border: 'none',
                padding: '10px 15px',
                borderRadius: '4px',
                marginTop: '20px',
                cursor: 'pointer',
                        }}
            onClick={removeData}>
                {isRemoving ? "Clearing.." : cleared ? "Cleared." : "Clear Browsing History"}
            </button>
          </div>
{/* 
          <div className="sign-out">
            <button
             onClick={handleSignOut}>Sign Out</button>
          </div> */}
        </div>
      ) : (
        <p>You need to sign in to access your profile.</p>
      )}
    </div>
    </div>
    </>
  );
};

export default UserProfile;
